export default {
    name:'my',
    install(Vue) {
        // 通用的组件
        Vue.component('MyDialog', () => import('./comm/my-dialog.vue')); // 弹框
        Vue.component('MyDrawer', () => import('./comm/my-drawer.vue')); // 抽屉
        Vue.component('MyPageSelect', () => import("./comm/my-page-select.vue")); // 分页选择
        Vue.component('MyTags', () => import('./comm/my-tags.vue'));  // 多个标签
        Vue.component('MyArea', () => import('./comm/my-area.vue')); // 地区选择，省；市；区/县
        Vue.component('MyUpimg', () => import('./comm/my-upimg.vue')); //  图片上传
        Vue.component('MyEdit', () => import('./comm/my-edit.vue'));  // 在线编辑器
        Vue.component('MyCopy', () => import('./comm/my-copy.vue')); // 复制

        // 页面的组件
        Vue.component('MyHead', () => import('./page/my-head.vue')); // 头部
        Vue.component('MyFoot', () => import('./page/my-foot.vue')); // 底部
        Vue.component('MyLeft', () => import('./page/my-left.vue')); // 左边
        Vue.component('MyCategory', () => import('./page/my-category.vue')); // 主菜单
        Vue.component('MySlide', () => import('./page/my-slide.vue')); // 幻灯片
        Vue.component('MyYoushi', () => import('./page/my-youshi.vue')); // 优势
        Vue.component('MySpage', () => import('./page/my-spage.vue')); // 单页面处理
        Vue.component('MyNews', () => import('./page/my-news.vue')); // 新闻资讯
        Vue.component('MyBusiness', () => import('./page/my-business.vue')); // 公司业务
    }
}