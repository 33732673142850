import store from "@/store";
const config = {
    host:'https://api.yingwujiao.com',
    appid:'20190910162018488388',
    secret:'8de539e654f1f3a43679f9017fa3c63b',
    ver:'1.0.0',
    selftoken:'fz',
    debug:process.env.NODE_ENV == 'production'  ? false : true
}

// eslint-disable-next-line no-undef
const lime = new Lime(config);
if (store.state.InitData.toekn_num == 0) {
    lime.set_token();
    store.commit('setTokenNum', 1);
}

if (lime.get_token() == null) {
    lime.set_token();
}
export default lime;