<template>
  <div id="app">
        <keep-alive>
            <router-view  v-wechat-title='$route.meta.title' />
        </keep-alive>
  </div>
</template>

<script>
    import Vue from "vue";
    import store from "@/store";
    import util from "@/util.js";
    import lime from "@/lime.js";


    if (util.empty(store.state.AppData)) {
        Vue.set(store.state, 'AppData', {
                height: '100vh',
                width: '100vw',
                img_host:'https://api.yingwujiao.com',

                kefuConfig:null,
                loginConfig:null,
                categoryList:[]
        });
    }

    export default {
        data() {
            return store.state.AppData;
        },
        created() {
            this.init();
        },
        mounted() {
            this.height = document.documentElement.clientHeight || document.body.clientHeight;
            this.width = document.documentElement.clientWidth || document.body.clientWidth;

            window.onresize = () => {
                this.height = document.documentElement.clientHeight || document.body.clientHeight;
                this.width = document.documentElement.clientWidth || document.body.clientWidth;
            }

        },
        methods: {
            init(){
                this.getKefuConfig();
                this.getLoginConfig();
                this.getCategoryList();
            },

            getCategoryList() {
                lime.req("CommManageCategoryAllList", {
                    order_field:'sort_num',
                    order_sort:'asc'
                }).then(res =>{
                    this.categoryList = res.data;
                    this.categoryList.forEach(item=>{
                        item.style= item.unselect_style;
                    })
                })
            },

            getLoginConfig() {
                if (util.empty(this.loginConfig)) {
                    lime.req("CommManageLoginView",{}).then(res =>{
                        this.loginConfig = res.data;
                    })
                }
            },

            getKefuConfig(){
                if (util.empty(this.kefuConfig)) {
                    lime.req("CommHelpSetView", {}).then(res =>{
                        this.kefuConfig = res.data;
                    })
                }
            }
        }
    }
</script>

<style>
*{
  padding:0;
  margin:0;
  outline :none; /*去除谷歌浏览器默认边框 */
}

.main{
    min-width: 1200px;
    max-width: 1600px;
    margin:0 auto;
}

.el-upload-list__item.is-ready {
  display: none !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.menu{
    display: inline-block;
    padding:0 16px;
    text-align: center;
}

.page {
    height: 39px; 
    line-height: 39px; 
    text-align: right;
    position: fixed;
    bottom: 0;
    right:10px;
    overflow: hidden;
}

.drawer-body{
    padding:0 15px;
    margin-top:-15px;
    border-top:solid 1px #e6e6e6;
    overflow: auto;
}
.drawer-footer{
    padding: 0 10px;
    height: 44px;
    line-height: 44px;
    border-top:solid 1px #e6e6e6;
    background-color:#fafafa;
}

.topnav_box::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    background-color: #b5b1b1;
}

.topnav_box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: black;
}

.topnav_box::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b5b1b1;
}

/* 级联菜单去掉单选按钮 */
.el-cascader-panel .el-radio {
 width: 100%;
 height: 100%;
 z-index: 10;
 position: absolute;
 top: 0px;
 right: 10px;
}
.el-cascader-node__label {
 width: 157px;
}
.el-cascader-panel .el-radio__input {
 visibility: hidden;
}
.el-cascader-panel .el-cascader-node__postfix {
 top: 10px;
}

.el-table th {
    overflow: hidden;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: #f2f1f4 !important;
    height: 46px;
    color:#333;
    border:none;
}


.cont{
    background-color: #fafafa;
    border:solid 1px #f2f1f4;
    border-radius: 4px;
    padding:5px 10px;
    min-height:400px;
}

.menu{
    display: inline-block;
    padding:0 16px;
    text-align: center;
}

/* 表不自动换行 */
.el-table td.el-table__cell div{
    white-space: nowrap;
}

.white {
    white-space: nowrap;
    overflow: hidden;/*超出部分溢出*/
    text-overflow: ellipsis;/*溢出的部分使用省略号*/
}

.line1{
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;/*溢出的部分使用省略号*/
}

.line2{
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;/*溢出的部分使用省略号*/
}

.line3{
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;/*溢出的部分使用省略号*/
}
</style>
