// 主入口
import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 更新html.title
Vue.use(require('vue-wechat-title'))

// 引用第三方库的图标
import './assets/icon/iconfont.css';

// 用来使用节流和防抖
import _ from "lodash";
Vue.prototype._ = _;



// 自定义组件
import My from "@/components/my";
Vue.use(My);


import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
